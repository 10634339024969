import styled from "styled-components";
import { devices } from "../../Theme.css";

type PostCardWrapperType = {
  loading: boolean;
  isShared: boolean;
};

export const PostCardWrapper = styled.div<PostCardWrapperType>`
  ${(props) => props.loading && `opacity: 0.8;`}
  width: 100%;
  border-radius: 40px;
  background: #fff;
  white-space: normal;
  ${(props) =>
    !props.isShared && `box-shadow: 1px 1px 6px 0px rgba(64, 26, 15, 0.25)`};
  ${(props) => props.isShared && `border: 1px solid #eee`};
  padding: 24px;
  .image-container {
    width: 100%;
    height: 281px;
    overflow: hidden;
    background: #eee;
    display: flex;
    justify-content: center;
  }
  img {
    object-fit: contain;
  }
  
  // Thumbnail width
  .feALpP {
    min-width: 100%;
  }
  .description {
    margin: 16px 0px;
  }

  .actions-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .icon {
      margin-right: 12px;
    }
  }

  .divider {
    border-bottom: 1px solid #eee;
    margin: 16px 0;
  }

  @media ${devices.max_sm} {
    box-shadow: none;
    .divider {
      border-bottom: none;
    }
  }
`;

export const NameAndTime = styled.div<{ isHoveredAndTruncated?: boolean }>`
  width: max-content;
  max-width: 92%;
  padding-left: 12px;
  text-align: left;
  color: black;
  word-wrap: break-word;
  .name {
    display: block;
    font-weight: bold;
    ${(props) =>
      props.isHoveredAndTruncated && `color: ${props.theme.color.primary};`}
    ${(props) => !props.isHoveredAndTruncated && `color: black;`}
    transition: 1s;
  }

  .time {
    display: block;
    width: max-content;
    text-align: left;
    font-weight: normal;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;

  .profile-link-container {
  }

  .title-and-time {
    /* width: max-content; */
    width: max-content;
    margin-left: 24px;
    background: yellow;

    .title {
      width: max-content;
      text-align: left;
      /* background-color: red; */
    }

    p:first-of-type {
      font-weight: bold;
    }
    p:last-of-type {
      font-weight: normal;
    }
  }

  .more-icon-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  button {
    font-weight: bold;
  }
`;

export const ProfilePicture = styled.div`
  background-color: #eee;
  width: 52px !important;
  height: 52px !important;
  border-radius: 50% !important;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    display: block;
  }

  :hover {
    border: 2px solid #eee;
    transition: 0.25s;
  }
`;

export const CommentButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;

  .comment-count {
    margin-left: 8px;
  }
`;

export const SharedPostWrapper = styled.div`
  width: 100%;
  border: 1px solid #eee;
  /* background: red; */
  border-radius: 40px;
  padding: 24px;
  box-shadow: 1px 1px 6px 0px rgba(64, 26, 15, 0.25);
  .actions-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 24px;
    .icon {
      margin-right: 12px;
    }
  }

  .more-icon-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  @media ${devices.max_sm} {
    box-shadow: none;
    border-radius: 0px;
  }
`;

export const AuthorWrapper = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const Author = styled.button`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    display: block;
    width: max-content;
    max-width: 150px;
  }
`;

export const Picture = styled.div`
  border: 1px solid #eee;
`;

export const Name = styled.div`
  margin-left: 12px;
  font-weight: bold;
`;

export const CaptionWrapper = styled.div`
  margin-bottom: 24px;
`;
